import Vue from 'vue';
import I18n from 'vue-i18n';
Vue.use(I18n);

export default new I18n({
    locale: "zh-CN",
    fallbackLocale: "zh-CN",
    // silentFallbackWarn: true,
    messages: {
        "zh-CN": require("./lang/zh-CN"),
        "zh-HK": require("./lang/zh-HK"),
        "en-US": require("./lang/en-US"),
        "ar-AE": require("./lang/ar-AE"),
        "pl-PL": require("./lang/pl-PL"),
        "fa": require("./lang/fa"),
        "da-DK": require("./lang/da-DK"),
        "de-AT": require("./lang/de-AT"),
        "ru-RU": require("./lang/ru-RU"),
        "fr-FR": require("./lang/fr-FR"),
        "nl-NL": require("./lang/nl-NL"),
        "cs-CZ": require("./lang/cs-CZ"),
        "ro-RO": require("./lang/ro-RO"),
        "pt-BR": require("./lang/pt-BR"),
        "ja-JP": require("./lang/ja-JP"),
        "th-TH": require("./lang/th-TH"),
        "tr-TR": require("./lang/tr-TR"),
        "ca-ES": require("./lang/ca-ES"),
        "el-GR": require("./lang/el-GR"),
        "it-IT": require("./lang/it-IT"),
        "vi": require("./lang/vi"),
        "hi-IN": require("./lang/hi-IN"),
    }
})