import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export const constantRoutes = [
  {
    name: 'subscription',
    path: '/',
    component: () => import('@/views/subscription/NewIndex')
  },
  // {
  //   name: 'NewIndex',
  //   path: '/NewIndex',
  //   component: () => import('@/views/subscription/NewIndex')
  // },
  // 舟海
  {
    name: 'zhzn',
    path: '/zhzn',
    component: () => import('@/views/subscription/zhzn')
  },
  {
    name: 'payment',
    path: '/payment',
    component: () => import('@/views/payment/index')
  },
  {
    path: '/order',
    component: () => import('@/views/set/orderPage')
  },
  {
    name: "invoicePage",
    path: '/invoicePage',
    component: () => import('@/views/set/invoicePage')
  },
  {
    name: 'refundPage',
    path: '/refundpage',
    component: () => import('@/views/set/refundPage')
  },
  {
    path: '/unsubscribe',
    component: () => import('@/views/subscription/unsubscribe')
  },
  {
    path: '/language',
    component: () => import('@/views/set/languagePage')
  },
  {
    path: '/problem',
    component: () => import('@/views/set/problemPage')
  },
  {
    path: '/notice',
    component: () => import('@/views/set/noticePage')
  },
  {
    name: "noticeDetail",
    path: '/notice/detail',
    component: () => import('@/views/set/noticeDetail')
  },
  
  // 隐私政策
  {
    name: 'us',
    path: '/us',
    component: () => import('@/views/policy/us')
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: () => import('@/views/policy/privacy')
  },
  {
    name: 'refund',
    path: '/refund',
    component: () => import('@/views/policy/refund')
  },
  {
    name: 'service',
    path: '/service',
    component: () => import('@/views/policy/service')
  },
]


let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
// 防止连续点击多次路由报错
