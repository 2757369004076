module.exports = {
    language: "捷克语",


    setting: {
        index: {
            title: "Nastavení",
            problem: "Zpětná vazba k problému",
            order: "Záznam objednávek",
            notice: "Oznámení",
            language: "Jazyk",
            clear: "Vymazat mezipaměť",
            clearTip: "Chcete potvrdit \n vymazání mezipaměti?",
            confirm: "Potvrdit",
            cancel: "Zrušit",
        },
        language: {
            title: "Jazyk",
            langList: ["Zjednodušená čínština", "tradiční čínština", "Angličtina", "arabština", "polština", "Peršan", "dánština", "Němec", "ruština", "francouzština", "holandský", "čeština", "rumunština", "portugalština", "japonský", "thajština", "turečtina", "španělština", "řecký", "italština", "vietnamština","hindština"],
        },
        order: {
            title: "Záznam objednávek",
            success: "Získání úspěšné",
            unpaid: "Nezaplaceno",
            paid: "Zaplaceno",
            expired: "Vypršelo",
            refunded: "Vráceno",
            pTime: "Čas platby",
            eTime: "Čas vypršení",
            pMethod: "Způsob platby",
            pObject: "Objekt platby",
            oNumber: "Číslo objednávky",
            pType: "Typ balíčku",
            oTime: "Čas získání",
            watch: "Sledovat IMEI",
            delete: "Smazat objednávku",
            refund: "Požádat o vrácení peněz",
            empty: "Žádný záznam ještě",
            grade: "Úroveň balíčku",
            package: "Stav balíčku",
            use: "Stav použití",
            remaining: "Zůstatek balíčku",
            basic: "Normální",
            pro: "Pokročilý",
            refundAmount: "Částka refundace"
        },
        problem: {
            title: "Zpětná vazba k problému",
            feedback: "Typ zpětné vazby",
            please: "Vyberte prosím",
            content: "Obsah",
            improve: "Napište prosím své návrhy, jako jsou požadavky na funkce, stížnosti na produkty atd. Budeme se snažit zlepšit",
            upload: "Nahrát obrázek",
            submit: "Odeslat",
            general: "Obecná zpětná vazba",
            purchase: "Týkající se nákupu",
            bug: "Hlásit chybu",
            suggestion: "Návrh",
            other: "Jiné",
        },
        notice: {
            title: "Oznámení",
            type: "Typ oznámení: Systémová odpověď",
            time: "Čas oznámení: "
        }
    },
    subscription: {
        jarvis: {
            funcList: ["AI otázky a odpovědi", "AI kreslení", "Základní verze chytré AI", "Pokročilá chytrá AI", "Multimodální vstup a výstup", "Podrobnější otázky a odpovědi", "Chytřejší modely", "Rychlejší odpověď"],
            times: "Krát",
            days: "Dní",
            more: "Pokročilá AI",
            custom: "Vlastní chytrá AI",
            delay: "Na připsání vašeho účtu může být mírné zpoždění.Prosím, vyčkejte a opusťte aplikaci hodinek a znovu se do ní přihlaste.",
            member: "Porovnání členů",
            packageName: "Název balíčku",
            unlimited: "neomezený",
            month: "Měsíc",
            },
        index: {
            nav: {
                l: "Pro",
                r: "Čas",
                language: "Jazyk",
                notice: "Oznámení",
                feedback: "Zpětná vazba"
            },
            proBox: {
                expire: {
                    title: "Datum vypršení",
                    date: "Ještě není přihlášeno k odběru",
                },
                packTip: "Máme různé měsíční balíčky",
                packList: ["Supreme Subscription", "Basic Subscription", "Normal subscription"],
                benefit: {
                    origin: "Původní cena: ",
                    remaining: "Zbývající",
                    title: "Výhody předplatného",
                    value: "hodnota",
                    benefitList: [
                        { labelTime: "Neomezený počet", labelSave: "Uložit obrázek", labelPer: "Plná oprávnění" },
                        { labelTime: "300krát", labelSave: "Úložiště záznamu", labelPer: "Základní oprávnění" },
                        { labelTime: "100krát", labelSave: "Úložiště záznamu", labelPer: "Nízká oprávnění" },
                    ],
                    desc: "$3.99/měsíc obsahuje 100 bezplatných použití, $9.99/měsíc obsahuje 300 bezplatných použití.",
                    tip: "Dojde k mírnému zpoždění při připsání počtu použití na váš účet. Počkejte chvíli, ukončete aplikaci hodinek a znovu do ní vstupte."
                },
            },
            timeBox: {
                packTip: "Máme různé měsíční balíčky",
                packList: ["Balíček Times se slevou pro nové uživatele", "Balíček 50krát", "Balíček 100krát", "Balíček 300krát"],
                times: {
                    title: "Role Times",
                    timesList: ["Lze použít na kreslení", "K dispozici pro Q&A GPT", "Používá se pro věštění horoskopu", "Lze použít pro učení", "Používá se k rozpoznávání objektů",],
                    desc: "50 použití za $2.99, 100 použití za $4.99, 300 použití za $12.99. Balíček 50 použití za $0.99 je pro nové uživatele a lze jej zakoupit pouze jednou (dostupný do 24 hodin)",
                }
            },
            staticBox: {
                confirm: "Potvrdit",
                privacy: "Soukromí",
                terms: "Podmínky",
                refund: "Vrácení peněz",
                cancel: "Zrušit kdykoliv",
                funcTitle: "Uvolněte svou kreativitu s PRO zkušeností",
                function: "Funkce",
                free: "Zdarma",
                pro: "Pro",
                funcList: ["Times je platný jeden den", "10 Times Draw/Chat (tři šance k aktivaci)", "Jedno přihlášení na 30 dní", "Více Times k použití", "Obrázky uchovány navždy", "Počet nákupů"],
                details: "Detaily",
            },
            dialog: {
                error: "Platba není dostupná. Prosím, skenujte QR kód na vašich hodinkách.",
                unbind: "Hodinky byly vázány na platební metodu. Chcete ji rozvázat?",
                beforePay: "V nákupu můžete pokračovat pouze tehdy, když jsou prémiové časy vyčerpány/vypršely a prémiové předplatné je zrušeno.",
                confirm: "Potvrdit",
                cancel: "Zrušit",
            }
        },
        NewIndex:{
            l:"Základní",
            r:"Pro",
            proBox:{
                benefit:{
                    title:"Balíček zahrnuje",
                    benefitList:[
                        {labelTime: "Získat časy",labelSave: "normální časy"},
                        {labelTime: "Získat časy",labelSave: "Pro časy"},
                        {labelTime: "Odemknout funkci",labelSave: "Odemknout všechny normální funkce"},
                        {labelTime: "Odemknout funkci",labelSave: "Odemkněte všechny prémiové funkce"},
                    ]
                }
            },
            funcList: ["Q&A", "AI kreslení", "Základní verze chytré AI", "Pokročilá chytrá AI", "Text na řeč", "Podrobnější odpovědi", "Rychlejší odpovědi"],
            confirm:'Upgrade nyní',
            dialog:{
                beforePay: "Přihlásili jste se k běžnému členství. Pokud pokračujete v nákupu prémiového členství, bude vám vrácena částka podle skutečného využití běžného členství. Chcete pokračovat?",
            }
        },
        unsubscribe: {
            title: "Nepřetržité předplatné",
            subTitle: "Předplácení",
            unsubscribe: "Odhlásit odběr",
            subscribeTime: "Čas předplatného",
        },
        refund: {
            title: "Požadovat vrácení peněz",
            number: "Číslo objednávky",
            content: "Vysvětlete prosím důvod vrácení peněz",
        },
        order: {
            number: "Číslo objednávky",
            name: "Název objednávky",
            times: "Počet objednávek",
            validity: "Platnost",
            buy: "Koupit nyní",
            days: "Počet dnů",
            unlock: "Odemknout všechna inteligentní AI",
            text: "Text na řeč",
        },
        zhzn: {
            title: "Členské výhody",
            t1: "Neomezené dotazy",
            d1: "Neomezené používání funkce Q&A během členství",
            t2: "AI kreslení",
            d2: "Odemknout oprávnění pro AI kreslení, může provádět funkci textového kreslení",
            t3: "Nastavení ciferníku",
            d3: "Ciferník může být nastaven podle výsledků AI kreslení",
        }
    },
    payment: {
        index: {
            contact: "Kontaktní informace",
            please: "Zadejte svou e-mailovou adresu",
            email: "Potvrzení platby bude zasláno na tento e-mail",
            name: "Jméno na kartě",
            firstName: "Křestní jméno",
            lastName: "Příjmení",
            confidence: "Neuchováváme žádné z vašich informací, prosím, zadejte je s důvěrou",
            Card: "Informace o kartě",
            confirm: "Potvrzením svého předplatného umožňujete Jarvis strhnout z vaší karty platby v souladu s jejich podmínkami. Svůj odběr můžete kdykoli zrušit.",
            powered: "Zajišťuje stripe",
            terms: "Podmínky",
            privacy: "Soukromí",
        }
    },
    policy: {
        title: {
            terms: "Podmínky",
            about: "O nás",
            service: "Podmínky služby",
            privacy: "Zásady ochrany osobních údajů",
            refund: "Zásady vrácení peněz",
            us: "O nás",
        },
        service: {
            section1: `<p>Webová stránka se nachází na adrese https://aiwatchstore.org/ je chráněným dílem patřícím společnosti HONG KONG ADFLASH MEDIA LIMITED. Některé funkce webu mohou podléhat dodatečným pokynům, podmínkám nebo pravidlům, které budou v souvislosti s těmito funkcemi zveřejněny na webu.</p>
<p>Všechny takové dodatečné podmínky, pokyny a pravidla jsou začleněny odkazem do těchto Podmínek.</p>
<p>Tyto Podmínky používání popisují právně závazné podmínky, které řídí vaše používání webu. PŘIHLÁŠENÍM NA WEB SOUHLASÍTE S TĚMITO PODMÍNKAMI a prohlašujete, že máte oprávnění a způsobilost vstoupit do těchto Podmínek. MĚLI BYSTE MÍT ALESPOŇ 18 LET, ABYSTE MĚLI PŘÍSTUP K WEBU. POKUD NESOUHLASÍTE SE VŠEMI USTANOVENÍMI TĚCHTO PODMÍNEK, NEPŘIHLAŠUJTE SE A/NEBO NEPOUŽÍVEJTE WEB.</p>
<p>Tyto podmínky vyžadují použití arbitráže v rámci Oddílu 10.2 na individuálním základě k řešení sporů a také omezují prostředky, které máte k dispozici v případě sporu.</p>
<h2>Přístup k webu</h2>
<p><strong>S výhradou těchto Podmínek.</strong> Udělujeme vám nepřenosnou, nevýhradní, odvolatelnou, omezenou licenci pro přístup na web pouze pro vaše osobní, nekomerční použití.</p>
<p>Vyhrazujeme si právo změnit, pozastavit nebo ukončit web s nebo bez oznámení vám. Souhlasíte, že nebudeme nést odpovědnost vůči vám ani žádné třetí straně za jakoukoli změnu, přerušení nebo ukončení webu nebo jakékoli jeho části.</p>
<p><strong>Žádná podpora ani údržba.</strong> Souhlasíte, že nebudeme mít žádnou povinnost poskytovat vám jakoukoli podporu v souvislosti s webem.</p>
<p>Bez ohledu na jakýkoli Uživatelský obsah, který můžete poskytnout, jste si vědomi toho, že všechna práva duševního vlastnictví, včetně autorských práv, patentů, ochranných známek a obchodních tajemství, na webu a jeho obsahu vlastníme my nebo naši dodavatelé. Všimněte si, že tyto Podmínky a přístup k webu vám neudělují žádná práva, titul ani zájem na žádných právech duševního vlastnictví, kromě omezených přístupových práv uvedených v Oddílu 2.1. My a naši dodavatelé si vyhrazujeme všechna práva, která nejsou udělena v těchto Podmínkách.</p>`,
            section2: `<h2>Podmínky předplatného</h2>
<p>Vítejte v naší službě předplatného (dále jen „Služba“). Tuto službu poskytuje HONG KONG ADFLASH MEDIA LIMITED (dále jen „my“ nebo „Společnost“). Používáním nebo předplatným naší služby souhlasíte s dodržováním těchto Podmínek předplatného (dále jen „Podmínky“).</p>
<p>Poskytneme vám služby na základě předplatného, včetně exkluzivních uživatelských kvót. Specifické nabídky služeb a poplatky se mohou kdykoli změnit a budou aktualizovány prostřednictvím oznámení e-mailem nebo na našem webu.</p>
<p>Poplatky za předplatné, platební metody a platební cykly jsou podrobně popsány na stránce s ceníkem. Účty s opožděnými platbami budou mít své placené služby dočasně pozastaveny.</p>
<p>Všechny poplatky za předplatné jsou nevratné, pokud to nevyžaduje zákon nebo podle našeho uvážení.</p>
<p>Předplatné můžete kdykoli zrušit. Po zrušení vám budeme i nadále poskytovat služby až do konce aktuálního období předplatného, ale žádné poplatky již zaplacené nebudou vráceny.</p>
<p>Vyhrazujeme si také právo jednostranně ukončit nebo pozastavit vaši službu v následujících případech:</p>
<p>- Porušení těchto Podmínek</p>
<p>- Neplacení poplatků za předplatné</p>
<p>Vynasnažíme se poskytovat vysoce kvalitní služby, ale neposkytujeme žádné výslovné ani implicitní záruky týkající se dostupnosti, přesnosti nebo úplnosti služby.</p>`,
            section3: `<h2>Ostatní uživatelé</h2>
<p><strong>Ostatní uživatelé.</strong> Každý uživatel webu je výhradně zodpovědný za jakýkoli a veškerý svůj vlastní Uživatelský obsah. Protože neovládáme Uživatelský obsah, uznáváte a souhlasíte s tím, že nejsme zodpovědní za žádný Uživatelský obsah, ať už jej poskytujete vy nebo jiní. Souhlasíte, že nebudeme zodpovědní za žádnou ztrátu nebo škodu vzniklou v důsledku takových interakcí. Pokud dojde k sporu mezi vámi a jakýmkoli uživatelem webu, nemáme povinnost se do něj zapojit.</p>
<p>Tímto nás včetně našich úředníků, zaměstnanců, agentů, nástupců a přiřazených osob propouštíte a zbavujete každého jednotlivého minulého, přítomného i budoucího sporu, nároku, kontroverze, požadavku, práva, závazku, odpovědnosti, žaloby a příčiny žaloby jakéhokoli druhu a povahy, které vznikly nebo vznikají, přímo či nepřímo, a které se přímo nebo nepřímo vztahují k webu. Pokud jste obyvatelem Kalifornie, tímto se vzdáváte kalifornského občanského zákoníku sekce 1542 v souvislosti s výše uvedeným, který stanoví: "všeobecné propuštění se nevztahuje na nároky, které věřitel nezná nebo nemá podezření, že existují v jeho prospěch v době vydání propuštění, což kdyby bylo známo jemu nebo jí, mohlo by materiálně ovlivnit jeho nebo její vyrovnání s dlužníkem."</p>
<p><strong>Cookies a webové majáky.</strong> Jako jakýkoli jiný web používá „cookies“. Tyto cookies se používají k ukládání informací včetně předvoleb návštěvníků a stránek na webu, které návštěvník navštívil nebo na nich přistál. Informace se používají k optimalizaci uživatelského zážitku přizpůsobením obsahu našich webových stránek na základě typu prohlížeče návštěvníků a/nebo jiných informací.</p>
<h2>Odmítnutí odpovědnosti</h2>
<p>Web je poskytován na základě "tak, jak je" a "jak je dostupný", a my a naši dodavatelé výslovně odmítáme jakékoli a veškeré záruky a podmínky jakéhokoli druhu, ať už výslovné, implicitní nebo statutární, včetně všech záruk nebo podmínek prodejnosti, vhodnosti pro konkrétní účel, titul, klidné užívání, přesnosti nebo neporušení. My a naši dodavatelé negarantujeme, že web splní vaše požadavky, bude dostupný nepřetržitě, včas, bezpečně nebo bez chyb, nebo že bude přesný, spolehlivý, bez virů nebo jiného škodlivého kódu, kompletní, legální nebo bezpečný. Pokud platné právo vyžaduje jakékoli záruky ohledně webu, všechny tyto záruky jsou omezeny na dobu devadesáti (90) dnů od data prvního použití.</p>
<p>Některé jurisdikce nedovolují vyloučení implicitních záruk, proto výše uvedené vyloučení se na vás nemusí vztahovat. Některé jurisdikce nedovolují omezení, jak dlouho trvá implicitní záruka, proto výše uvedené omezení se na vás nemusí vztahovat.</p>`,
            section4: `<h2>Omezení odpovědnosti</h2>
<p>V největším možném rozsahu povoleném zákonem nesmíme my ani naši dodavatelé nést odpovědnost vůči vám nebo jakékoli třetí straně za jakékoliv ztráty zisku, ztrátu dat, náklady na získání náhradních produktů nebo jakékoli nepřímé, následné, exemplární, náhodné, zvláštní nebo trestní škody vyplývající z těchto podmínek nebo vašeho použití nebo neschopnosti používat web, i když jsme byli na možnost takových škod upozorněni. Přístup a používání webu je na vaše vlastní uvážení a riziko a budete výhradně zodpovědní za jakékoli poškození vašeho zařízení nebo počítačového systému nebo ztrátu dat z toho vyplývající.</p>
<p>V největším možném rozsahu povoleném zákonem, bez ohledu na cokoli jiného uvedeného zde, naše odpovědnost vůči vám za jakékoli škody vyplývající z této smlouvy bude vždy omezena na maximálně padesát amerických dolarů (u.s. $50). Existence více než jednoho nároku nezvětší tento limit. Souhlasíte, že naši dodavatelé nebudou mít žádnou odpovědnost jakéhokoli druhu vyplývající z této smlouvy.</p>
<p>Některé jurisdikce nedovolují omezení nebo vyloučení odpovědnosti za náhodné nebo následné škody, takže výše uvedené omezení nebo vyloučení se na vás nemusí vztahovat.</p>
<p><strong>Doba trvání a ukončení.</strong> S výhradou tohoto oddílu, tyto Podmínky zůstávají v plné platnosti a účinnosti, zatímco používáte web. Můžeme pozastavit nebo ukončit vaše práva na používání webu kdykoli z jakéhokoli důvodu podle našeho vlastního uvážení, včetně jakéhokoli použití webu v rozporu s těmito Podmínkami. Po ukončení vašich práv podle těchto Podmínek bude váš účet a právo na přístup a používání webu okamžitě ukončeno. Chápete, že jakékoli ukončení vašeho účtu může zahrnovat smazání vašeho Uživatelského obsahu spojeného s vaším účtem z našich živých databází. Nebudeme mít vůči vám žádnou odpovědnost za jakékoli ukončení vašich práv podle těchto Podmínek. I po ukončení vašich práv podle těchto Podmínek zůstávají v platnosti následující ustanovení těchto Podmínek: Oddíly 2 až 2.5, Oddíl 3 a Oddíly 4 až 10.</p>
<p></p>
<p>Odškodnění. Budete hájit, odškodňovat a chránit nás, naše přidružené společnosti a náš personál před a proti jakýmkoli nárokům, ztrátám a výdajům (včetně právních poplatků) vyplývajícím z vašeho používání Služeb, včetně vašeho Obsahu, produktů nebo služeb, které vyvíjíte nebo nabízíte v souvislosti se Službami, a vašeho porušení těchto Podmínek nebo porušení platných zákonů.</p>
<p>Odmítnutí odpovědnosti. SLUŽBY JSOU POSKYTOVÁNY „TAK, JAK JSOU.“ S VÝJIMKOU V ROZSAHU ZAKÁZANÉM ZÁKONEM NEMÁME MY ANI NAŠI PŘIDRUŽENÍ PARTNEŘI A POSKYTOVATELÉ LICENCÍ ŽÁDNÉ ZÁRUKY (VÝSLOVNÉ, IMPLICITNÍ, ZÁKONNÉ NEBO JINÉ) TÝKAJÍCÍ SE SLUŽEB A ODMÍTÁME VŠECHNY ZÁRUKY, VČETNĚ, ALE NEJEN, ZÁRUK PRODEJNOSTI, VHODNOSTI PRO KONKRÉTNÍ ÚČEL, USPÓKOJIVÉ KVALITY, NEPORUŠENÍ PRÁV A KLIDNÉHO UŽÍVÁNÍ A JAKÝCHKOLIV ZÁRUK VYPLÝVAJÍCÍCH Z KTERÉHOKOLI OBCHODNÍHO JEDNÁNÍ NEBO OBYČEJNÝCH ČINNOSTÍ. NEZARUČUJEME, ŽE SLUŽBY BUDOU NEPŘERUŠENÉ, PŘESNÉ NEBO BEZCHYBNÉ, NEBO ŽE JAKÝKOLIV OBSAH BUDE BEZPEČNÝ NEBO NEBUDE ZTRACEN NEBO ZMĚNĚN.</p>`,
            section5: `<h2>Pravidla pro ochranu autorských práv.</h2>
<p>Respektujeme duševní vlastnictví ostatních a žádáme uživatele našeho webu, aby činili totéž. V souvislosti s naším webem jsme přijali a implementovali politiku respektující zákon o autorských právech, která stanoví odstranění veškerých materiálů porušujících autorská práva a ukončení používání našeho online webu u uživatelů, kteří opakovaně porušují práva duševního vlastnictví, včetně autorských práv. Pokud se domníváte, že jeden z našich uživatelů prostřednictvím používání našeho webu nezákonně porušuje autorská práva k dílu a chcete mít údajně porušující materiál odstraněn, musí být následující informace ve formě písemného oznámení (v souladu s 17 U.S.C. § 512(c)) poskytnuty našemu určenému zástupci pro autorská práva:</p>
<p>- vaše fyzický nebo elektronický podpis;</p>
<p>- identifikace autorského díla (děl), které podle vašeho tvrzení bylo porušeno;</p>
<p>- identifikace materiálu na našich službách, který podle vašeho tvrzení porušuje autorská práva a který žádáte, aby byl odstraněn;</p>
<p>- dostatečné informace, které nám umožní takový materiál lokalizovat;</p>
<p>- vaše adresa, telefonní číslo a e-mailová adresa;</p>
<p>- prohlášení, že máte v dobré víře přesvědčení, že použití obstruktivního materiálu není autorizováno vlastníkem autorských práv, jeho agentem nebo ze zákona; a</p>
<p>- prohlášení, že informace v oznámení jsou přesné a pod trestem křivé přísahy, že jste buď vlastníkem autorských práv, která byla údajně porušena, nebo že jste oprávněni jednat jménem vlastníka autorských práv.</p>
<p>Vezměte prosím na vědomí, že v souladu s 17 U.S.C. § 512(f), jakékoli nepravdivé prohlášení o materiální skutečnosti v písemném oznámení automaticky vystavuje stěžující stranu odpovědnosti za jakoukoli škodu, náklady a právní poplatky, které nám vzniknou v souvislosti s písemným oznámením a tvrzením o porušení autorských práv.</p>`,
            section6: `<h2>Všeobecné</h2>
<p>Tyto Podmínky mohou být čas od času revidovány, a pokud provedeme jakékoli podstatné změny, můžeme vás o tom informovat zasláním e-mailu na poslední e-mailovou adresu, kterou jste nám poskytli, a/nebo výrazným zveřejněním oznámení o změnách na našem webu. Jste zodpovědní za to, abyste nám poskytli svou nejaktuálnější e-mailovou adresu. V případě, že poslední e-mailová adresa, kterou jste nám poskytli, není platná, naše odeslání e-mailu obsahujícího takové oznámení přesto představuje účinné oznámení o změnách popsaných v oznámení. Jakékoli změny těchto Podmínek budou účinné nejdříve třicet (30) kalendářních dnů po našem odeslání e-mailového oznámení vám nebo třicet (30) kalendářních dnů po našem zveřejnění oznámení o změnách na našem webu. Tyto změny budou okamžitě účinné pro nové uživatele našeho webu. Pokračující používání našeho webu po oznámení takových změn bude znamenat vaše potvrzení těchto změn a souhlas s tím, že budete vázáni podmínkami a ustanoveními těchto změn. Řešení sporů. Přečtěte si prosím toto Arbitrážní ujednání pozorně. Je součástí vaší smlouvy s námi a ovlivňuje vaše práva. Obsahuje postupy pro POVINNOU ZÁVAZNOU ARBITRÁŽ A ZŘEKNUTÍ SE HROMADNÉ ŽALOBY.</p>
<p><strong>Použitelnost arbitrážního ujednání.</strong> Všechny nároky a spory v souvislosti s Podmínkami nebo používáním jakéhokoli produktu či služby poskytované námi, které nelze vyřešit neformálně nebo u soudu pro drobné nároky, budou řešeny závaznou arbitráží na individuálním základě podle podmínek tohoto Arbitrážního ujednání. Pokud není dohodnuto jinak, všechna arbitrážní řízení se budou konat v angličtině. Toto Arbitrážní ujednání se vztahuje na vás a na nás a na jakékoli dceřiné společnosti, přidružené společnosti, agenty, zaměstnance, právní předchůdce, právní nástupce a přiřazené osoby, stejně jako na všechny autorizované nebo neautorizované uživatele nebo příjemce služeb nebo zboží poskytovaných podle Podmínek.</p>
<p><strong>Požadavek na oznámení a neformální řešení sporů.</strong> Předtím, než kterákoliv strana může usilovat o arbitráž, musí nejprve zaslat druhé straně písemné Oznámení o sporu, ve kterém popíše povahu a základ nároku nebo sporu a požadované odškodnění. Oznámení nám by mělo být zasláno na náš kontaktní e-mail. Po obdržení Oznámení se můžete vy a my pokusit neformálně vyřešit nárok nebo spor. Pokud vy a my nevyřešíme nárok nebo spor do třiceti (30) dnů po obdržení Oznámení, může kterákoliv strana zahájit arbitrážní řízení. Výše jakékoli nabídky vyrovnání učiněné kteroukoli stranou nesmí být zveřejněna arbitrovi až po stanovení výše náhrady, na kterou má kterákoliv strana nárok.</p>
<p><strong>Arbitrážní pravidla.</strong> Arbitráž bude zahájena prostřednictvím Americké arbitrážní asociace (AAA), zavedeného poskytovatele alternativního řešení sporů, který nabízí arbitráž, jak je stanoveno v tomto oddílu. Pokud AAA není k dispozici pro arbitráž, strany se dohodnou na výběru alternativního poskytovatele ADR. Pravidla poskytovatele ADR budou řídit všechny aspekty arbitráže, s výjimkou míry, do jaké tato pravidla jsou v rozporu s Podmínkami. Pravidla spotřebitelské arbitráže AAA, která řídí arbitráž, jsou k dispozici online na adr.org nebo zavoláním na AAA na číslo 1-800-778-7879. Arbitráž bude vedena jediným, neutrálním arbitrem. Jakékoliv nároky nebo spory, kde celková částka požadovaného odškodnění je menší než Deset tisíc amerických dolarů (US $10,000.00), mohou být vyřešeny závaznou neosobní arbitráží, podle volby strany žádající o nápravu. U nároků nebo sporů, kde celková částka požadovaného odškodnění je Deset tisíc amerických dolarů (US $10,000.00) nebo více, bude právo na slyšení určeno podle arbitrážních pravidel. Jakékoliv slyšení bude uspořádáno na místě do 100 mil od vašeho bydliště, pokud nebydlíte mimo území Spojených států, a pokud se strany nedohodnou jinak. Pokud bydlíte mimo území Spojených států, arbitr poskytne stranám přiměřené oznámení o datu, čase a místě jakéhokoliv ústního slyšení. Jakýkoliv rozsudek o náhradě udělený arbitrem může být zaznamenán na jakémkoli příslušném soudě. Pokud vám arbitr udělí náhradu, která je vyšší než poslední nabídka vyrovnání, kterou jsme vám učinili před zahájením arbitráže, zaplatíme vám buď náhradu, nebo $2,500.00, podle toho, která částka je vyšší. Každá strana ponese své vlastní náklady a výdaje vzniklé z arbitráže a zaplatí rovný podíl na poplatcích a nákladech poskytovatele ADR.</p>
<p><strong>Dodatečná pravidla pro neosobní arbitráž.</strong> Pokud je zvolena neosobní arbitráž, arbitráž bude vedena telefonicky, online a/nebo pouze na základě písemných podání; konkrétní způsob bude vybrán stranou zahajující arbitráž. Arbitráž nebude zahrnovat žádné osobní setkání stran nebo svědků, pokud se strany nedohodnou jinak.</p>
<p><strong>Časové limity.</strong> Pokud vy nebo my usilujeme o arbitráž, musí být arbitrážní řízení zahájeno a/nebo požadováno ve lhůtě stanoveno podle promlčecí lhůty a v souladu s jakoukoliv lhůtou uloženou arbitrážními pravidly AAA pro příslušný nárok.</p>
<p><strong>Pravomoc arbitra.</strong> Pokud je arbitráž zahájena, arbitr rozhodne o právech a závazcích vás a nás, a spor nebude spojen s žádnými jinými záležitostmi ani spojen s žádnými jinými případy nebo stranami. Arbitr bude mít pravomoc vydávat rozhodnutí ověřující všechna nebo část jakéhokoli nároku. Arbitr bude mít pravomoc udělit peněžní odškodnění a poskytnout jakoukoli nepeněžní náhradu nebo úlevu, kterou má jednotlivec k dispozici podle platného práva, pravidel AAA a Podmínek. Arbitr vydá písemné ocenění a prohlášení o rozhodnutí, které popisuje základní nálezy a závěry, na kterých je ocenění založeno. Arbitr má stejnou pravomoc poskytovat úlevu na individuálním základě, jakou by měl soudce u soudu. Rozhodnutí arbitra je konečné a závazné pro vás i nás.</p>
<p><strong>Vzdání se práva na soudní proces před porotou.</strong> STRANY TÍMTO SE VZDÁVAJÍ SVÝCH KONSTITUČNÍCH A ZÁKONNÝCH PRÁV NA SOUDNÍ ŘÍZENÍ PŘED SOUDCEM NEBO POROTOU, místo toho volí, že všechny nároky a spory budou vyřešeny arbitráží podle tohoto arbitrážního ujednání. Postupy arbitráže jsou obvykle více omezené, efektivnější a méně nákladné než pravidla platná u soudu a podléhají velmi omezenému přezkumu soudem. V případě, že by mezi vámi a námi vznikla jakákoliv soudní pře u jakéhokoliv státního nebo federálního soudu ve snaze zrušit nebo vynutit arbitrážní rozhodnutí nebo jinak, VY A MY SE VZDÁVÁME VŠECH PRÁV NA SOUDNÍ ŘÍZENÍ PŘED POROTOU, místo toho volíme, že spor bude vyřešen soudcem.</p>
<p><strong>Vzdání se hromadných nebo kolektivních žalob.</strong> Všechny nároky a spory v rozsahu této arbitrážní smlouvy musí být arbitrovány nebo řešeny jednotlivě a ne hromadně, a nároky více než jednoho zákazníka nebo uživatele nemohou být arbitrovány ani řešeny společně nebo spojeny s nároky jakéhokoli jiného zákazníka nebo uživatele.</p>
<p><strong>Důvěrnost.</strong> Všechny aspekty arbitrážního řízení budou přísně důvěrné. Strany se zavazují zachovávat důvěrnost, pokud zákon nevyžaduje jinak. Tento odstavec nezabraňuje straně předložit soudu jakékoli informace nezbytné k vynucení této smlouvy, vynucení arbitrážního rozhodnutí nebo k zajištění soudní nebo spravedlivé úlevy.</p>`,
            section7: `<p><strong>Oddělitelnost.</strong> Pokud jakákoliv část nebo části tohoto Arbitrážního ujednání budou podle zákona shledány příslušným soudem jako neplatné nebo nevynutitelné, taková konkrétní část nebo části budou neúčinné a budou odděleny a zbytek Ujednání zůstane v plné platnosti.</p>
<p><strong>Právo na vzdání se.</strong> Jakékoli nebo všechna práva a omezení stanovená v tomto Arbitrážním ujednání mohou být vzdána stranou, proti které je nárok uplatněn. Takové vzdání se nebude znamenat vzdání se nebo ovlivnění jakékoli jiné části tohoto Arbitrážního ujednání.</p>
<p><strong>Trvání Ujednání.</strong> Toto Arbitrážní ujednání přežije ukončení vašeho vztahu s námi.</p>
<p><strong>Soud pro drobné nároky.</strong> Bez ohledu na výše uvedené, vy nebo my můžeme podniknout individuální akci u soudu pro drobné nároky.</p>
<p><strong>Nouzová spravedlivá úleva.</strong> Bez ohledu na výše uvedené může kterákoliv strana požádat o nouzovou spravedlivou úlevu před státním nebo federálním soudem za účelem udržení stavu quo před arbitráží. Žádost o předběžná opatření nebude považována za vzdání se jakýchkoli dalších práv nebo povinností v rámci tohoto Arbitrážního ujednání.</p>
<p><strong>Nároky nepodléhající arbitráži.</strong> Bez ohledu na výše uvedené, nároky na pomluvu, porušení zákona o počítačových podvodech a zneužití a porušení nebo přisvojení patentu, autorského práva, ochranné známky nebo obchodního tajemství druhé strany nebudou podléhat tomuto Arbitrážnímu ujednání.</p>
<p>V jakýchkoli případech, kdy toto Arbitrážní ujednání umožňuje stranám soudní řízení, se strany tímto dohodly podrobit se osobní jurisdikci soudů nacházejících se v okrese Netherlands, Kalifornie, pro takové účely.</p>
<p>Web může podléhat americkým zákonům o kontrole vývozu a může podléhat vývozním nebo dovozním předpisům v jiných zemích. Souhlasíte s tím, že nebudete vyvážet, reexportovat nebo převádět, přímo nebo nepřímo, žádná technická data z USA získaná od nás nebo jakékoli produkty využívající taková data, v rozporu se zákony nebo předpisy USA o vývozu.</p>`,
            section8: `<p><strong>Elektronická komunikace.</strong> Komunikace mezi vámi a námi probíhá elektronicky, ať už používáte web nebo nám posíláte e-maily, nebo my zveřejňujeme oznámení na webu nebo s vámi komunikujeme prostřednictvím e-mailu. Pro smluvní účely tímto (a) souhlasíte s přijímáním komunikace od nás v elektronické formě; a (b) souhlasíte s tím, že všechny podmínky, dohody, oznámení, zveřejnění a další komunikace, které vám poskytujeme elektronicky, splňují jakoukoli právní povinnost, kterou by taková komunikace splňovala, pokud by byla v tištěné podobě.</p>
<p><strong>Úplné podmínky.</strong> Tyto Podmínky představují úplnou dohodu mezi vámi a námi ohledně používání webu. Naše selhání při výkonu nebo vymáhání jakéhokoli práva nebo ustanovení těchto Podmínek nebude znamenat vzdání se takového práva nebo ustanovení. Názvy oddílů v těchto Podmínkách slouží pouze pro pohodlí a nemají žádný právní nebo smluvní účinek. Slovo "včetně" znamená "včetně bez omezení". Pokud je některé ustanovení těchto Podmínek shledáno neplatným nebo nevynutitelným, ostatní ustanovení těchto Podmínek zůstanou neporušena a neplatné či nevynutitelné ustanovení bude považováno za upravené tak, aby bylo platné a vynutitelné v maximální možné míře povolené zákonem. Váš vztah k nám je vztahem nezávislého dodavatele a žádná ze stran není agentem nebo partnerem druhé strany. Tyto Podmínky a vaše práva a povinnosti zde uvedené nesmíte přiřadit, podkontrahovat, delegovat nebo jinak převést bez našeho předchozího písemného souhlasu, a jakýkoli pokus o přiřazení, podkontraktaci, delegování nebo převod v rozporu s uvedeným bude neplatný a neúčinný. My můžeme tyto Podmínky volně přiřadit. Podmínky a ustanovení uvedené v těchto Podmínkách budou závazné pro přiřazence.</p>
<p><strong>Ochrana soukromí.</strong> Přečtěte si prosím naše Zásady ochrany osobních údajů.</p>
<p><strong>Informace o autorských právech/ochranných známkách.</strong> Copyright ©. Všechna práva vyhrazena. Všechny ochranné známky, loga a služby zobrazené na webu jsou naším majetkem nebo majetkem jiných třetích stran. Nesmíte používat tyto značky bez našeho předchozího písemného souhlasu nebo souhlasu takové třetí strany, která může vlastnit značky.</p>
<h2>Kontaktní informace</h2>
<p>Email: watch_support@adflash.cn</p>`
        },
        privacy: {
            section1: ` <p>Ochrana vašeho soukromí je pro nás důležitá. Doufáme, že následující prohlášení vám pomůže pochopit, jak produkty nakládají s osobními identifikačními údaji, které nám občas můžete poskytnout prostřednictvím internetu.</p>
<p>Na základě našich aplikací a souvisejících služeb můžete požádat o použití speciální funkční služby poskytované třetí stranou. Pokud se potřebujete zaregistrovat a používat jakoukoli speciální funkční službu, pečlivě si přečtěte podmínky služby pro tuto speciální funkční službu. Přijetím nebo používáním jakékoli speciální funkční služby potvrzujete, že jste si přečetli a přijali tuto smlouvu a podmínky služby pro tuto speciální funkční službu a souhlasíte s nimi být vázáni.</p>
<p>Někdy potřebujeme informace k poskytování služeb, které požadujete, toto prohlášení o ochraně osobních údajů se vztahuje na jeho produkty.</p>
<h2>Informace, které tým shromažďuje a přijímá.</h2>
<p>Když používáte Software a Služby, můžeme automaticky shromažďovat a uchovávat následující typy informací:</p>
<p>1. Technické informace. Určité technické informace o vašem zařízení a vašem používání Softwaru a Služeb a dalšího softwaru třetích stran a aplikací nainstalovaných ve vašem zařízení ("Jiný software"), včetně typu, názvu a verze operačního systému a Jiného softwaru včetně, mimo jiné, jejich interakcí se Softwarem a Službami, poskytovatelem internetových služeb (ISP), určování polohy (GPS), adresa internetového protokolu (IP), anonymní identifikátory uživatelů, konfigurace a identifikátory zařízení (IDFA (Identifikátor pro inzerenty používaný v iOS), Reklamní ID (Identifikátor pro inzerenty používaný v Androidu) a podobně), verze Softwaru a Služeb a vaše konfigurace a nastavení Softwaru a Služeb;</p>
<p>2. Informace o používání. Určité informace související s používáním a interakcí s vaším zařízením, včetně Softwaru a Služeb a Jiného softwaru, například kdy a jak používáte Software a Služby a Jiný software. Například aplikace, které máte a používáte, a jak často je používáte. Reklamy, které si prohlížíte, používáte a přistupujete k nim, jak je používáte a vaše reakce na ně;</p>
<p>3. Sociální sítě. Vaše interakce na sociálních sítích prostřednictvím Softwaru a Služeb;</p>`,
            section2: ` <p>4. Informace související s registrací. Informace, které poskytnete při registraci pro Software a Služby (pokud je to relevantní), jako je jméno, domácí nebo pracovní adresy, e-mailová adresa, telefonní a faxová čísla, datum narození a pohlaví;</p>
<p>5. Informace související s podporou. Pokud s námi komunikujete prostřednictvím naší sekce podpory nebo e-mailem, můžeme také shromažďovat vaši e-mailovou adresu, jméno a jakékoli informace dobrovolně poskytnuté v souvislosti s podporou. Když používáte Software a Služby, automaticky generujeme anonymní interní ID uživatele, které je přiřazeno k vašemu používání Softwaru a Služeb (dále jen "Uživatelské ID") a může být kombinováno s jakoukoli výše uvedenou informací.</p>
<p>6. Informace o zařízení</p>
<p>Shromažďujeme informace o vašem zařízení, včetně hardwarového modelu, operačního systému a verze, jedinečných identifikátorů zařízení (včetně SSAID, GAID), typu a jazyka prohlížeče, telefonního čísla mobilního zařízení a informací o mobilní síti, Mezinárodní identifikátor mobilního zařízení („IMEI“), Identifikátor pro reklamu („IDFA“), Identifikátor pro prodejce („IDFV“), Identifikátor integrované obvodové karty („ICCID“), Adresa MAC (Media Access Control), model a rozlišení, které budou použity pro výpočet počtu zařízení, která používají naše produkty a Služby a analyzování dat o modelech zařízení a grafických adaptacích. Můžete se rozhodnout neposkytnout určité informace, ale pak možná nebudete moci využít mnoho z našich aplikací. Také shromažďujeme určité informace o zařízení, které nám pomohou diagnostikovat problémy v (doufejme vzácném) případě, že při používání našich služeb dojde k nějakému selhání nebo jinému problému.</p>
<p>7. Kamera a fotky</p>
<p>Mnoho našich služeb vyžaduje, abychom shromažďovali obrázky a další informace z kamery a fotek vašeho zařízení. K vaší kameře a fotkám přistoupíme pouze poté, co nám k tomu dáte svůj souhlas. Můžete se rozhodnout ho neposkytnout, ale pak možná nebudete moci využít mnoho z našich služeb.</p>
<p>8. Místní úložiště</p>
<p>Můžeme shromažďovat a ukládat informace lokálně na vašem zařízení pomocí mechanismů, jako je webové úložiště prohlížeče (včetně HTML 5) a mezipaměť dat aplikací. Informace, které uložíme, nebudou sdíleny s žádnou třetí stranou.</p>
<p>9. NEOSOBNÍ INFORMACE</p>`,
            section3: ` <p> Neosobní informace jsou data ve formě, která neumožňuje přímé spojení s žádnou konkrétní osobou, například vaše Android ID, model CPN, velikost paměti, číslo IMEI vašeho telefonu, model telefonu, rom, název nainstalované aplikace a název balíčku, telefonní operátor, poloha, instalace, odinstalace, frekvence používání atd.</p>
<p> Můžeme shromažďovat a používat neosobní informace v následujících případech. K lepšímu porozumění chování uživatelů, řešení problémů v produktech a službách, zlepšení našich produktů, služeb a reklam můžeme shromažďovat neosobní informace, jako je název nainstalované aplikace a název balíčku, data instalace, frekvence používání, země, vybavení a kanál.</p>
<h2>Jak tým používá vaše informace?</h2>
<p>Tým může používat shromážděné informace pro analytické, obchodní a provozní účely následujícími způsoby:</p>
<p>1. k provozování a aktualizaci Softwaru a Služeb a poskytování stávajících a nových funkcionalit a funkcí;</p>
<p>2. k vylepšení a přizpůsobení Softwaru a Služeb;</p>
<p>3. k poskytování personalizovaného obsahu, včetně doručování cílených reklam založených na zájmech od nás nebo našich partnerů;</p>
<p>4. k udržení a zlepšení kvality a provozu Softwaru a Služeb;</p>
<p>5. k oznámení o aktualizacích Softwaru a Služeb;</p>
<p>6. k poskytování podpory a řešení vašich stížností a/nebo zpětné vazby;</p>
<p>7. k přijímání jakýchkoli opatření v případě sporu týkajícího se vás v souvislosti se Softwarem a Službami;</p>`,
            section4: ` <p>8. k vynucování podmínek použitelných pro Software a Služby a k zabránění nezákonných aktivit a jejich zneužití;</p>
<p>9. ke splnění a dodržení jakéhokoli platného zákona, nařízení, právního procesu nebo vymahatelného vládního požadavku. Sdílení informací s třetími stranami.</p>
<h2>Ochrana soukromí dětí</h2>
<p>Tyto služby nejsou určeny nikomu mladšímu 13 let. Vědomě nezhromažďuji osobní identifikační údaje od dětí mladších 13 let. Pokud zjistím, že dítě mladší 13 let nám poskytlo osobní údaje, okamžitě je smažu z našich serverů. Pokud jste rodič nebo opatrovník a zjistíte, že vaše dítě nám poskytlo osobní údaje, kontaktujte mě, abych mohl podniknout nezbytné kroky.</p>
<h2>Změny v této politice ochrany osobních údajů</h2>
<p>Čas od času mohu aktualizovat naši politiku ochrany osobních údajů. Proto se doporučuje pravidelně kontrolovat tuto stránku na jakékoli změny. O jakýchkoli změnách vás budu informovat zveřejněním nové politiky ochrany osobních údajů na této stránce. Tyto změny nabývají účinnosti okamžitě po jejich zveřejnění na této stránce.</p>
<h2>Kontaktujte nás</h2>
<p>Pokud máte jakékoli otázky nebo návrhy týkající se mé politiky ochrany osobních údajů, neváhejte mě kontaktovat na adrese .E-mail: watch_support@adflash.cn</p>`,


        },
        refund: {
            section1: `<p>Děkujeme za váš nákup. Pokud nejste s nákupem zcela spokojeni, rádi bychom vám pomohli.</p>
<p>Pro zahájení procesu vrácení peněz postupujte podle níže uvedených kroků.</p>
<p>Produkty na předplatné (např. Základní předplatné)</p>
<p>Pokud přestanete používat, ale zapomenete zrušit své předplatné, obvykle vám rádi vrátíme vaši poslední platbu za předplatné, pokud jste po provedení platby nevyužili žádné sezení nebo nevytvořili žádné obrázky.</p>
<p>Pro požadavek na vrácení peněz nám prosím pošlete žádost o vrácení peněz prostřednictvím svého účtu a formuláře zpětné vazby. Můžeme potřebovat požádat vás o konkrétní informace, které nám pomohou ověřit vaši totožnost.</p>
<p>Digitální produkty (např. balíčky časových jednotek, předplatné)</p>
<p>Nebudou poskytnuty žádné náhrady, pokud byly jakékoli balíčky časových jednotek a předplatné použity. Nenabízíme částečné nebo proporcionální vrácení peněz.</p>
<p>Máte 14 kalendářních dnů od data transakce na požádání o vrácení peněz.</p>
<p>Jakmile obdržíme vaši žádost o vrácení peněz, naši pracovníci zkontrolují váš stav členství, aby určili, zda máte nárok na vrácení peněz.</p>`,
            section2: `<p>Pokud budete schváleni, vaše vrácení peněz bude zpracováno. Náhrada bude připsána na původní [způsob platby] použitý pro nákup.</p>
<p>Zpožděné nebo chybějící vrácení peněz</p>
<p>Pokud jste neobdrželi vrácení peněz, nejdříve prosím znovu zkontrolujte svůj bankovní účet.</p>
<p>Poté kontaktujte svou bankovní kartu, může to trvat nějaký čas, než bude vaše vrácení peněz oficiálně uvolněno.</p>
<p>Dále kontaktujte svou banku. Zpracování obvykle trvá nějaký čas, než je vrácení peněz uvolněno.</p>
<p>Pokud jste udělali vše a stále jste neobdrželi vrácení peněz, kontaktujte nás prosím na watch_support@adflash.cn</p>
<p>Položky ve slevě</p>
<p>Bohužel, pouze položky s plnou cenou mohou být vráceny, položky ve slevě nemohou být vráceny.</p>`,
        },
        us: {
            section1: `<p>Informace o společnosti</p>
<p>Název: HONG KONG ADFLASH MEDIA LIMITED</p>
<p>Adresa: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
<p>Email: watch_support@adflash.cn</p>`,
        },
    }
}