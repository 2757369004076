module.exports = {
    language: "葡萄牙语",


    setting: {
        index: {
            title: "Configuração",
            problem: "Feedback de problemas",
            order: "Registro de pedidos",
            notice: "Aviso",
            language: "Idioma",
            clear: "Limpar cache",
            clearTip: "Você quer confirmar \n Limpar cache?",
            confirm: "Confirmar",
            cancel: "Cancelar",
        },
        language: {
            title: "linguagem",
            langList: ["Chinês simplificado", "chinês tradicional", "Inglês", "árabe", "polonês", "persa", "dinamarquês", "Alemão", "russo", "Francês", "Holandês", "Tcheco", "romena", "Português", "japonês", "tailandês", "turco", "espanhol", "grego", "italiano", "vietnamita", "hindi"],
        },
        order: {
            title: "Registro de pedidos",
            success: "Sucesso na obtenção",
            unpaid: "Não pago",
            paid: "Pago",
            expired: "Expirado",
            refunded: "Reembolsado",
            pTime: "Hora do pagamento",
            eTime: "Hora de expiração",
            pMethod: "Método de pagamento",
            pObject: "Objeto de pagamento",
            oNumber: "Número do pedido",
            pType: "Tipo de pacote",
            oTime: "Hora de obtenção",
            watch: "Assistir IMEI",
            delete: "Excluir pedido",
            refund: "Solicitar reembolso",
            empty: "Nenhum registro ainda",
            grade: "Nível do pacote",
            package: "Estado do pacote",
            use: "Estado de uso",
            remaining: "Saldo do pacote",
            basic: "Normal",
            pro: "Avançado",
            refundAmount: "Valor do reembolso"
        },
        problem: {
            title: "Feedback de problemas",
            feedback: "Tipo de Feedback",
            please: "Por favor escolha",
            content: "Conteúdo",
            improve: "Por favor escreva suas sugestões, como requisitos funcionais, reclamações sobre o produto, etc. Nós nos esforçaremos para melhorar",
            upload: "Fazer upload de imagem",
            submit: "Enviar",
            general: "Feedback geral",
            purchase: "Relacionado à compra",
            bug: "Relatório de bug",
            suggestion: "Sugestão",
            other: "Outro",
        },
        notice: {
            title: "Aviso",
            type: "Tipo de notificação: resposta do sistema",
            time: "Hora da notificação: "
        }
    },
    subscription: {
        jarvis: {
            funcList: ["Perguntas e Respostas de IA", "Desenho de IA", "Versão básica de IA inteligente", "IA inteligente avançada", "Entrada e saída multimodal", "Perguntas e Respostas mais detalhadas", "Modelos mais inteligentes", "Resposta mais rápida"],
            times: "Vezes",
            days: "Dias",
            more: "IA mais avançada",
            custom: "IA inteligente personalizada",
            delay: "Pode haver um leve atraso na acreditação da sua conta. Por favor, aguarde e saia do aplicativo do relógio e entre novamente.",
            member: "Comparação de membros",
            packageName: "Nome do pacote",
            unlimited: "ilimitado",
            month: "Mês",
            },
        index: {
            nav: {
                l: "Pro",
                r: "Tempo",
                language: "Idioma",
                notice: "Aviso",
                feedback: "Feedback"
            },
            proBox: {
                expire: {
                    title: "Data de expiração",
                    date: "Ainda não assinado",
                },
                packTip: "Temos uma variedade de diferentes pacotes mensais",
                packList: ["Assinatura Suprema", "Assinatura Básica", "Assinatura Normal"],
                benefit: {
                    origin: "Preço original: ",
                    remaining: "Restante",
                    title: "Benefícios da assinatura",
                    value: "valor",
                    benefitList: [
                        { labelTime: "Tempos ilimitados", labelSave: "Salvar imagem", labelPer: "Permissões completas" },
                        { labelTime: "300 vezes", labelSave: "Armazenamento de registro", labelPer: "Permissões básicas" },
                        { labelTime: "100 vezes", labelSave: "Armazenamento de registro", labelPer: "Privilégios baixos" },
                    ],
                    desc: "$3.99/Mês vem com 100 usos gratuitos, $9.99/Mês vem com 300 usos gratuitos.",
                    tip: "Haverá um pequeno atraso no número de vezes creditados em sua conta. Por favor, aguarde um momento e saia do aplicativo do relógio e entre novamente."
                },
            },
            timeBox: {
                packTip: "Temos uma variedade de diferentes pacotes mensais",
                packList: ["Pacote de desconto para novos usuários", "Pacote de 50 vezes", "Pacote de 100 vezes", "Pacote de 300 vezes"],
                times: {
                    title: "O papel dos tempos",
                    timesList: ["Pode ser usado para desenhos", "Disponível para perguntas e respostas GPT", "Usado para adivinhação do horóscopo", "Pode ser usado para o aprendizado", "Usado para reconhecer objetos",],
                    desc: "50 vezes de uso por $2.99, 100 vezes de uso por $4.99, 300 vezes de uso por $12.99. O pacote de 50 vezes por $0.99 é para novos usuários e só pode ser comprado uma vez (disponível dentro de 24 horas)",
                }
            },
            staticBox: {
                confirm: "Confirmar",
                privacy: "Privacidade",
                terms: "Termos",
                refund: "Reembolso",
                cancel: "Cancelar a qualquer momento",
                funcTitle: "Liberte sua criatividade com a experiência PRO",
                function: "Função",
                free: "Grátis",
                pro: "Pro",
                funcList: ["Os tempos são válidos por um dia", "10 Desenhos/Chats (Três chances para ativar)", "Assine uma vez por 30 dias", "Mais tempos para usar", "Imagens mantidas para sempre", "Número de vezes que você pode comprar"],
                details: "Detalhes",
            },
            dialog: {
                error: "Pagamento não disponível. Por favor, escaneie o código QR no seu relógio.",
                unbind: "O relógio está vinculado a um método de pagamento. Você quer desvinculá-lo?",
                beforePay: "Você só pode continuar a comprar quando os tempos premium se esgotarem/expirarem e a assinatura premium for cancelada.",
                confirm: "Confirmar",
                cancel: "Cancelar",
            }
        },
        NewIndex:{
            l:"Básico",
            r:"Pro",
            proBox:{
                benefit:{
                    title:"Pacote Inclui",
                    benefitList:[
                        {labelTime: "Obter Tempos",labelSave: "Tempos normais"},
                        {labelTime: "Obter Tempos",labelSave: "Tempos Pro"},
                        {labelTime: "Desbloquear função",labelSave: "Desbloquear todos os recursos normais"},
                        {labelTime: "Desbloquear função",labelSave: "Desbloqueie todos os recursos premium"},
                    ]
                }
            },
            funcList: ["Perguntas e Respostas", "Desenho AI", "Versão básica de AI Inteligente", "AI Inteligente Avançado", "Texto para fala", "Respostas mais detalhadas", "Respostas mais rápidas"],
            confirm:'Atualizar agora',
            dialog:{
                beforePay: "Você se inscreveu na associação comum. Se você continuar a comprar a associação premium, você será reembolsado de acordo com o uso real da associação comum. Você deseja continuar?",
            }
        },
        unsubscribe: {
            title: "Assinatura Contínua",
            subTitle: "Assinando",
            unsubscribe: "Cancelar Inscrição",
            subscribeTime: "Tempo de assinatura",   
        },
        refund: {
            title: "Reembolso pedido",
            number: "Número do pedido",
            content: "Por favor, explique o motivo do reembolso",         
        },
        order: {
            number: "Número do pedido",
            name: "Nome do pedido",
            times: "Número de vezes do pedido",
            validity: "Validade",
            buy: "Comprar agora",
            days: "Número de dias",
            unlock: "Desbloquear toda a IA inteligente",
            text: "Texto para fala",
        },
        zhzn: {
            title: "Privilegios de associação",
            t1: "Perguntas e respostas ilimitadas",
            d1: "Uso ilimitado da função de chat de perguntas e respostas durante a associação",
            t2: "Desenho AI",
            d2: "Desbloquear permissões de desenho AI, pode realizar a função de desenho por texto",
            t3: "Configurações do mostrador",
            d3: "O mostrador pode ser configurado de acordo com os resultados do desenho AI",
        }
    },
    payment: {
        index: {
            contact: "Informações de contato",
            please: "Por favor, insira seu endereço de e-mail",
            email: "O recibo de pagamento será enviado para este e-mail",
            name: "Nome no Cartão",
            firstName: "Primeiro nome",
            lastName: "Último nome",
            confidence: "Não retemos nenhuma das suas informações, por favor insira-as com confiança",
            Card: "Informações do Cartão",
            confirm: "Ao confirmar a sua assinatura, você permite que a Jarvis debite seu cartão para pagamentos futuros de acordo com seus termos. Você pode cancelar sua assinatura a qualquer momento.",
            powered: "Fornecido por Stripe",
            terms: "Termos",
            privacy: "Privacidade",
        }
    },
    policy: {
        title: {
            terms: "Termos",
            about: "Sobre",
            service: "Termos de Serviço",
            privacy: "Política de Privacidade",
            refund: "Política de Reembolso",
            us: "Sobre Nós",
        },
        service: {
            section1: `<p>O site localizado em https://aiwatchstore.org/ é uma obra protegida por direitos autorais pertencente à HONG KONG ADFLASH MEDIA LIMITED. Certas funcionalidades do Site podem estar sujeitas a diretrizes, termos ou regras adicionais que serão publicadas no Site em conexão com tais funcionalidades.</p>
<p>Todos esses termos, diretrizes e regras adicionais são incorporados por referência a estes Termos.</p>
<p>Estes Termos de Uso descrevem os termos e condições legalmente vinculativos que regem o uso do Site. AO LOGAR NO SITE, VOCÊ ESTÁ CONCORDANDO COM ESTES TERMOS e você representa que tem a autoridade e capacidade para entrar nestes Termos. VOCÊ DEVE TER PELO MENOS 18 ANOS DE IDADE PARA ACESSAR O SITE. SE VOCÊ DISCORDAR DE TODAS AS DISPOSIÇÕES DESTES TERMOS, NÃO FAÇA LOGIN E/OU USE O SITE.</p>
<p>Estes termos exigem a utilização de arbitragem Seção 10.2 de forma individual para resolver disputas e também limitam os recursos disponíveis para você em caso de disputa.</p>
<h2>Acesso ao Site</h2>
<p><strong>Sujeito a estes Termos.</strong> Concedemos a você uma licença não transferível, não exclusiva, revogável e limitada para acessar o Site unicamente para seu uso pessoal e não comercial.</p>
<p>Reservamos o direito de alterar, suspender ou encerrar o Site com ou sem aviso prévio a você. Você aprova que não seremos responsabilizados perante você ou qualquer terceiro por qualquer alteração, interrupção ou término do Site ou de qualquer parte.</p>
<p><strong>Sem Suporte ou Manutenção.</strong> Você concorda que não teremos nenhuma obrigação de fornecer suporte em relação ao Site.</p>
<p>Excluindo qualquer Conteúdo de Usuário que você possa fornecer, você está ciente de que todos os direitos de propriedade intelectual, incluindo direitos autorais, patentes, marcas registradas e segredos comerciais, no Site e seu conteúdo pertencem a nós ou aos nossos fornecedores. Observe que estes Termos e o acesso ao Site não concedem a você quaisquer direitos, título ou interesse em qualquer direito de propriedade intelectual, exceto pelos direitos limitados de acesso expressos na Seção 2.1. Nós e nossos fornecedores reservamos todos os direitos não concedidos nestes Termos.</p>`,
            section2: `<h2>Termos de Assinatura</h2>
<p>Bem-vindo ao nosso serviço de assinatura (doravante referido como o "Serviço"). Este serviço é fornecido pela HONG KONG ADFLASH MEDIA LIMITED (doravante referida como "nós" ou "a Empresa"). Ao usar ou assinar nosso serviço, você concorda em cumprir estes Termos de Assinatura (doravante referidos como os "Termos").</p>
<p>Forneceremos a você serviços baseados em assinatura, incluindo cotas exclusivas para usuários. As ofertas de serviço específicas e as taxas podem mudar a qualquer momento e serão atualizadas através de notificações por e-mail ou em nosso site.</p>
<p>As taxas de assinatura, os métodos de pagamento e os ciclos de pagamento são detalhados na página de preços. Contas com pagamentos atrasados terão seus serviços pagos temporariamente suspensos.</p>
<p>Todas as taxas de assinatura não são reembolsáveis, a menos que exigido por lei ou a nosso exclusivo critério.</p>
<p>Você pode cancelar sua assinatura a qualquer momento. Após o cancelamento, continuaremos a fornecer os serviços até o final do atual período de assinatura, mas não serão emitidos reembolsos para taxas já pagas.</p>
<p>Também nos reservamos o direito de rescindir ou suspender unilateralmente o seu serviço nas seguintes circunstâncias:</p>
<p>- Violação destes Termos</p>
<p>- Não pagamento das taxas de assinatura</p>
<p>Faremos todos os esforços para fornecer serviços de alta qualidade, mas não oferecemos garantias expressas ou implícitas em relação à disponibilidade, precisão ou integridade do serviço.</p>`,
            section3: `<h2>Outros Usuários</h2>
<p><strong>Outros Usuários.</strong> Cada usuário do Site é o único responsável por todo e qualquer Conteúdo do Usuário próprio. Como não controlamos o Conteúdo do Usuário, você reconhece e concorda que não somos responsáveis por qualquer Conteúdo do Usuário, seja fornecido por você ou por outros. Você concorda que não seremos responsáveis por qualquer perda ou dano incorrido como resultado de tais interações. Se houver uma disputa entre você e qualquer usuário do Site, não temos obrigação de nos envolver.</p>
<p>Você, por meio deste, libera e libera para sempre a nós e nossos diretores, funcionários, agentes, sucessores e cessionários de, e por meio deste renuncia e abandona, toda e qualquer disputa passada, presente e futura, reclamação, controvérsia, demanda, direito, obrigação, responsabilidade, ação e causa de ação de qualquer tipo e natureza, que tenha surgido ou surja direta ou indiretamente do site, ou que se relacione direta ou indiretamente com o Site. Se você é residente da Califórnia, renuncia por meio deste à seção 1542 do código civil da Califórnia em conjunto com o mencionado acima, que diz: "uma liberação geral não se estende a reivindicações que o credor não sabe ou suspeita que existam a seu favor no momento da execução da liberação, que, se conhecidas por ele ou ela, devem ter afetado materialmente seu acordo com o devedor."</p>
<p><strong>Cookies e Web Beacons.</strong> Como qualquer outro site, usamos ‘cookies’. Esses cookies são usados para armazenar informações, incluindo as preferências dos visitantes e as páginas no site que o visitante acessou ou visitou. As informações são usadas para otimizar a experiência dos usuários, personalizando o conteúdo da nossa página da web com base no tipo de navegador dos visitantes e/ou outras informações.</p>
<h2>Isenções de Responsabilidade</h2>
<p>O site é fornecido em uma base "como está" e "conforme disponível", e nós e nossos fornecedores expressamente isentamos quaisquer e todas as garantias e condições de qualquer tipo, sejam expressas, implícitas ou estatutárias, incluindo todas as garantias ou condições de comercialização, adequação a um propósito específico, título, quiet enjoyment, precisão ou não violação. Nós e nossos fornecedores não garantimos que o site atenderá às suas necessidades, estará disponível de maneira ininterrupta, pontual, segura ou livre de erros, ou será preciso, confiável, livre de vírus ou outro código prejudicial, completo, legal ou seguro. Se a lei aplicável exigir quaisquer garantias com relação ao site, todas essas garantias são limitadas a noventa (90) dias a partir da data do primeiro uso.</p>
<p>Algumas jurisdições não permitem a exclusão de garantias implícitas, então a exclusão acima pode não se aplicar a você. Algumas jurisdições não permitem limitações sobre quanto tempo uma garantia implícita dura, então a limitação acima pode não se aplicar a você.</p>`,
            section4: `<h2>Limitação de Responsabilidade</h2>
<p>Na máxima extensão permitida por lei, em nenhum caso nós ou nossos fornecedores seremos responsáveis perante você ou qualquer terceiro por quaisquer lucros cessantes, perda de dados, custos de aquisição de produtos substitutos, ou quaisquer danos indiretos, consequenciais, exemplares, incidentais, especiais ou punitivos decorrentes destes termos ou relacionados a eles ou ao seu uso ou incapacidade de usar o site, mesmo que tenhamos sido avisados da possibilidade de tais danos. O acesso e uso do site é a seu próprio critério e risco, e você será o único responsável por qualquer dano ao seu dispositivo ou sistema de computador, ou perda de dados resultante disso.</p>
<p>Na máxima extensão permitida por lei, não obstante qualquer disposição em contrário aqui contida, nossa responsabilidade perante você por quaisquer danos decorrentes deste acordo ou relacionados a ele será sempre limitada a um máximo de cinquenta dólares americanos (US$ 50). A existência de mais de uma reclamação não aumentará este limite. Você concorda que nossos fornecedores não terão qualquer responsabilidade de qualquer tipo decorrente deste acordo ou relacionada a ele.</p>
<p>Algumas jurisdições não permitem a limitação ou exclusão de responsabilidade por danos incidentais ou consequenciais, então a limitação ou exclusão acima pode não se aplicar a você.</p>
<p><strong>Termo e Rescisão.</strong> Sujeito a esta Seção, estes Termos permanecerão em pleno vigor e efeito enquanto você usar o Site. Nós podemos suspender ou rescindir seus direitos de usar o Site a qualquer momento, por qualquer motivo, a nosso exclusivo critério, incluindo qualquer uso do Site em violação destes Termos. Após a rescisão de seus direitos sob estes Termos, sua Conta e direito de acessar e usar o Site serão imediatamente encerrados. Você entende que qualquer término de sua Conta pode envolver a exclusão de seu Conteúdo de Usuário associado à sua Conta de nossos bancos de dados ao vivo. Nós não teremos qualquer responsabilidade perante você por qualquer término de seus direitos sob estes Termos. Mesmo após o término de seus direitos sob estes Termos, as seguintes disposições destes Termos permanecerão em vigor: Seções 2 a 2.5, Seção 3 e Seções 4 a 10.</p>
<p></p>
<p>Indenização. Você defenderá, indenizará e isentará de responsabilidade a nós, nossas afiliadas e nosso pessoal, de e contra quaisquer reivindicações, perdas e despesas (incluindo honorários advocatícios) decorrentes ou relacionadas ao seu uso dos Serviços, incluindo o seu Conteúdo, produtos ou serviços que você desenvolver ou oferecer em conexão com os Serviços, e sua violação destes Termos ou violação da lei aplicável.</p>
<p>Isenção de Responsabilidade. OS SERVIÇOS SÃO FORNECIDOS “COMO ESTÃO”. EXCETO NA MEDIDA PROIBIDA PELA LEI, NÓS E NOSSAS AFILIADAS E LICENCIADORES NÃO OFERECEMOS GARANTIAS (EXPRESSAS, IMPLÍCITAS, ESTATUTÁRIAS OU OUTRAS) COM RELAÇÃO AOS SERVIÇOS, E RECUSAMOS TODAS AS GARANTIAS INCLUINDO, MAS NÃO SE LIMITANDO A, GARANTIAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM FIM ESPECÍFICO, QUALIDADE SATISFATÓRIA, NÃO INFRAÇÃO, E USO PACÍFICO, E QUAISQUER GARANTIAS DECORRENTES DE QUALQUER CURSO DE NEGOCIAÇÃO OU USO COMERCIAL. NÃO GARANTIMOS QUE OS SERVIÇOS SERÃO ININTERRUPTOS, PRECISOS OU SEM ERROS, OU QUE QUALQUER CONTEÚDO SERÁ SEGURO OU NÃO PERDIDO OU ALTERADO.</p>`,
            section5: `<h2>Política de Direitos Autorais.</h2>
<p>Respeitamos a propriedade intelectual de terceiros e pedimos que os usuários do nosso Site façam o mesmo. Em conexão com nosso Site, adotamos e implementamos uma política de respeito à lei de direitos autorais que prevê a remoção de quaisquer materiais que infrinjam os direitos autorais e a rescisão dos usuários do nosso Site online que são infratores reincidentes de direitos de propriedade intelectual, incluindo direitos autorais. Se você acredita que um de nossos usuários está, através do uso do nosso Site, infringindo ilegalmente os direitos autorais de uma obra, e deseja que o material supostamente infrator seja removido, as seguintes informações na forma de uma notificação por escrito (de acordo com o 17 U.S.C. § 512(c)) devem ser fornecidas ao nosso Agente Designado de Direitos Autorais:</p>
<p>- sua assinatura física ou eletrônica;</p>
<p>- identificação da(s) obra(s) protegida(s) por direitos autorais que você alega terem sido infringidas;</p>
<p>- identificação do material em nossos serviços que você alega estar infringindo e que você solicita que removamos;</p>
<p>- informações suficientes para nos permitir localizar tal material;</p>
<p>- seu endereço, número de telefone e endereço de e-mail;</p>
<p>- uma declaração de que você tem uma crença de boa-fé de que o uso do material objetável não é autorizado pelo proprietário dos direitos autorais, seu agente ou pela lei; e</p>
<p>- uma declaração de que as informações na notificação são precisas, e sob pena de perjúrio, que você é o proprietário dos direitos autorais que supostamente foram infringidos ou que você está autorizado a agir em nome do proprietário dos direitos autorais.</p>
<p>Por favor, note que, nos termos do 17 U.S.C. § 512(f), qualquer deturpação de fato material em uma notificação por escrito automaticamente sujeita a parte reclamante a responsabilidade por quaisquer danos, custos e honorários advocatícios incorridos por nós em conexão com a notificação por escrito e alegação de violação de direitos autorais.</p>`,
            section6: `<h2>Geral</h2>
<p>Estes Termos estão sujeitos a revisões ocasionais e, se fizermos alterações substanciais, poderemos notificá-lo enviando um e-mail para o último endereço de e-mail que você nos forneceu e/ou publicando de forma destacada um aviso das alterações em nosso Site. Você é responsável por nos fornecer seu endereço de e-mail mais atualizado. No caso de o último endereço de e-mail que você nos forneceu não ser válido, o envio do e-mail contendo tal aviso constituirá, mesmo assim, aviso efetivo das mudanças descritas no aviso. Quaisquer alterações a estes Termos entrarão em vigor a partir do mais cedo entre trinta (30) dias corridos após o envio de um aviso por e-mail para você ou trinta (30) dias corridos após a publicação do aviso das alterações em nosso Site. Essas mudanças serão efetivas imediatamente para novos usuários de nosso Site. O uso continuado de nosso Site após o aviso de tais mudanças indicará seu reconhecimento de tais mudanças e acordo em se comprometer com os termos e condições de tais mudanças. Resolução de Disputas. Por favor, leia este Acordo de Arbitragem cuidadosamente. Ele faz parte do seu contrato conosco e afeta seus direitos. Ele contém procedimentos para ARBITRAGEM VINCULATIVA OBRIGATÓRIA E UMA RENÚNCIA DE AÇÃO COLETIVA.</p>
<p><strong>Aplicabilidade do Acordo de Arbitragem.</strong> Todas as reivindicações e disputas em conexão com os Termos ou o uso de qualquer produto ou serviço fornecido por nós que não possam ser resolvidas de forma informal ou em tribunal de pequenas causas serão resolvidas por arbitragem vinculativa numa base individual de acordo com os termos deste Acordo de Arbitragem. A menos que acordado de outra forma, todas as sessões de arbitragem serão realizadas em inglês. Este Acordo de Arbitragem se aplica a você e a nós, e a quaisquer subsidiárias, afiliadas, agentes, funcionários, antecessores no interesse, sucessores e cessionários, bem como todos os usuários autorizados ou não autorizados ou beneficiários de serviços ou bens fornecidos sob os Termos.</p>
<p><strong>Requisito de Aviso e Resolução Informal de Disputas.</strong> Antes que qualquer das partes possa buscar arbitragem, a parte deve primeiro enviar à outra parte um Aviso de Disputa por escrito descrevendo a natureza e a base da reivindicação ou disputa, e o alívio solicitado. Um Aviso para nós deve ser enviado para nosso e-mail de contato. Após o recebimento do Aviso, você e nós podemos tentar resolver a reivindicação ou disputa de forma informal. Se você e nós não resolvermos a reivindicação ou disputa dentro de trinta (30) dias após o recebimento do Aviso, qualquer das partes pode iniciar um procedimento de arbitragem. O valor de qualquer proposta de acordo feita por qualquer das partes não pode ser divulgado ao árbitro até que o árbitro tenha determinado o valor do prêmio ao qual qualquer das partes tem direito.</p>
<p><strong>Regras de Arbitragem.</strong> A arbitragem deverá ser iniciada através da American Arbitration Association, um fornecedor estabelecido de resolução alternativa de disputas que oferece arbitragem conforme descrito nesta seção. Se a AAA não estiver disponível para arbitrar, as partes deverão concordar em selecionar um Provedor de ADR alternativo. As regras do Provedor de ADR regerão todos os aspectos da arbitragem, exceto na medida em que tais regras estejam em conflito com os Termos. As Regras de Arbitragem do Consumidor da AAA, que regem a arbitragem, estão disponíveis online em adr.org ou ligando para a AAA no número 1-800-778-7879. A arbitragem será conduzida por um único árbitro neutro. Qualquer reivindicação ou disputa em que o valor total do prêmio pretendido seja inferior a Dez Mil Dólares Americanos (US $10.000,00) pode ser resolvida por meio de arbitragem vinculativa sem comparecimento, a critério da parte que busca reparação. Para reivindicações ou disputas em que o valor total do prêmio pretendido seja Dez Mil Dólares Americanos (US $10.000,00) ou mais, o direito a uma audiência será determinado pelas Regras de Arbitragem. Qualquer audiência será realizada em um local dentro de 100 milhas de sua residência, a menos que você resida fora dos Estados Unidos, e salvo acordo contrário entre as partes. Se você residir fora dos EUA, o árbitro deverá dar um aviso razoável às partes sobre a data, hora e local de qualquer audiência oral. Qualquer sentença proferida pelo árbitro pode ser registrada em qualquer tribunal de jurisdição competente. Se o árbitro lhe conceder um prêmio maior do que a última oferta de acordo que nós fizemos a você antes do início da arbitragem, nós pagaremos a você o maior valor entre o prêmio ou $2.500,00. Cada parte arcará com seus próprios custos e despesas decorrentes da arbitragem e pagará uma parte igual das taxas e custos do Provedor de ADR.</p>
<p><strong>Regras Adicionais para Arbitragem Sem Comparecimento.</strong> Se a arbitragem sem comparecimento for escolhida, a arbitragem será conduzida por telefone, online e/ou com base exclusivamente em submissões por escrito; a maneira específica será escolhida pela parte que iniciar a arbitragem. A arbitragem não envolverá qualquer comparecimento pessoal das partes ou testemunhas, a menos que acordado de outra forma pelas partes.</p>
<p><strong>Limites de Tempo.</strong> Se você ou nós buscar arbitragem, a ação de arbitragem deve ser iniciada e/ou demandada dentro do prazo de prescrição e dentro de qualquer prazo imposto pelas Regras da AAA para a reivindicação pertinente.</p>
<p><strong>Autoridade do Árbitro.</strong> Se a arbitragem for iniciada, o árbitro decidirá os direitos e responsabilidades entre você e nós, e a disputa não será consolidada com quaisquer outros assuntos ou juntada com quaisquer outros casos ou partes. O árbitro terá a autoridade para conceder moções dispositivas de toda ou parte de qualquer reivindicação. O árbitro terá a autoridade para conceder danos monetários e conceder qualquer recurso ou alívio não monetário disponível a um indivíduo sob a lei aplicável, as Regras da AAA e os Termos. O árbitro emitirá uma sentença por escrito e uma declaração de decisão descrevendo as conclusões e constatações essenciais nas quais a sentença se baseia. O árbitro tem a mesma autoridade para conceder alívio em uma base individual que um juiz em um tribunal de justiça. A sentença do árbitro é final e vinculativa para você e para nós.</p>
<p><strong>Renúncia ao Julgamento pelo Júri.</strong> AS PARTES RENUNCIAM AOS SEUS DIREITOS CONSTITUCIONAIS E ESTATUTÁRIOS DE IR AO TRIBUNAL E TER UM JULGAMENTO PERANTE UM JUIZ OU UM JÚRI, escolhendo em vez disso que todas as reivindicações e disputas sejam resolvidas por arbitragem sob este Acordo de Arbitragem. Os procedimentos de arbitragem são tipicamente mais limitados, mais eficientes e menos dispendiosos do que as regras aplicáveis em um tribunal e estão sujeitos a uma revisão muito limitada por um tribunal. No caso de qualquer litígio surgir entre você e nós em qualquer tribunal estadual ou federal em uma ação para anular ou executar uma sentença arbitral ou de outra forma, VOCÊ E NÓS RENUNCIAMOS A TODOS OS DIREITOS A UM JULGAMENTO PELO JÚRI, escolhendo em vez disso que a disputa seja resolvida por um juiz.</p>
<p><strong>Renúncia a Ações Coletivas ou Consolidadas.</strong> Todas as reivindicações e disputas dentro do escopo deste acordo de arbitragem devem ser arbitradas ou litigadas em base individual e não em base coletiva, e as reivindicações de mais de um cliente ou usuário não podem ser arbitradas ou litigadas em conjunto ou consolidadas com as de qualquer outro cliente ou usuário.</p>
<p><strong>Confidencialidade.</strong> Todos os aspectos do procedimento de arbitragem devem ser estritamente confidenciais. As partes concordam em manter a confidencialidade a menos que exigido por lei. Este parágrafo não impedirá uma das partes de apresentar a um tribunal de justiça qualquer informação necessária para fazer cumprir este Acordo, para executar uma sentença arbitral ou para buscar medidas cautelares ou alívio equitativo.</p>`,
            section7: `<p><strong>Separabilidade.</strong> Se qualquer parte ou partes deste Acordo de Arbitragem forem consideradas inválidas ou inexequíveis por um tribunal de jurisdição competente, então tal parte ou partes específicas não terão efeito e serão separadas, e o restante do Acordo continuará em pleno vigor e efeito.</p>
<p><strong>Direito de Renunciar.</strong> Qualquer um ou todos os direitos e limitações estabelecidos neste Acordo de Arbitragem podem ser renunciados pela parte contra a qual a reivindicação é apresentada. Tal renúncia não renunciará ou afetará qualquer outra parte deste Acordo de Arbitragem.</p>
<p><strong>Sobrevivência do Acordo. </strong>Este Acordo de Arbitragem sobreviverá ao término de seu relacionamento conosco.</p>
<p><strong>Tribunal de Pequenas Causas.</strong> No entanto, qualquer uma das partes pode trazer uma ação individual no tribunal de pequenas causas.</p>
<p><strong>Medida Cautelar de Emergência.</strong> De qualquer forma, qualquer uma das partes pode buscar medida cautelar de emergência em um tribunal estadual ou federal a fim de manter o status quo pendente de arbitragem. Um pedido de medidas provisórias não será considerado uma renúncia de quaisquer outros direitos ou obrigações sob este Acordo de Arbitragem.</p>
<p><strong>Reivindicações Não Sujeitas à Arbitragem.</strong> Não obstante o disposto acima, reivindicações de difamação, violação da Lei de Fraude e Abuso de Computadores, e violação ou apropriação indevida de patentes, direitos autorais, marcas comerciais ou segredos comerciais da outra parte não estão sujeitas a este Acordo de Arbitragem.</p>
<p>Em quaisquer circunstâncias em que o Acordo de Arbitragem acima permita que as partes litigem em tribunal, as partes concordam em se submeter à jurisdição pessoal dos tribunais localizados no Condado de Netherlands, Califórnia, para tais fins.</p>
<p>O Site pode estar sujeito às leis de controle de exportação dos EUA e pode estar sujeito a regulamentos de exportação ou importação em outros países. Você concorda em não exportar, reexportar ou transferir, direta ou indiretamente, quaisquer dados técnicos dos EUA adquiridos de nós, ou quaisquer produtos que utilizem tais dados, em violação das leis ou regulamentos de exportação dos Estados Unidos.</p>`,
            section8: `<p><strong>Comunicações Eletrônicas.</strong> As comunicações entre você e nós utilizam meios eletrônicos, seja você utilizando o Site ou enviando e-mails para nós, ou seja nós publicando avisos no Site ou comunicando com você via e-mail. Para fins contratuais, você (a) consente em receber comunicações de nós em forma eletrônica; e (b) concorda que todos os termos e condições, acordos, avisos, divulgações e outras comunicações que fornecemos a você eletronicamente satisfazem qualquer obrigação legal que tais comunicações satisfariam se fossem em forma escrita.</p>
<p><strong>Termos Integrais.</strong> Estes Termos constituem o acordo completo entre você e nós em relação ao uso do Site. Nossa falha em exercer ou aplicar qualquer direito ou disposição destes Termos não operará como uma renúncia de tal direito ou disposição. Os títulos das seções destes Termos são apenas para conveniência e não têm efeito legal ou contratual. A palavra "incluindo" significa "incluindo sem limitação". Se qualquer disposição destes Termos for considerada inválida ou inexequível, as demais disposições destes Termos não serão prejudicadas e a disposição inválida ou inexequível será considerada modificada para que seja válida e exequível na máxima extensão permitida por lei. Sua relação conosco é de um contratante independente, e nenhuma das partes é agente ou parceiro da outra. Estes Termos, e seus direitos e obrigações aqui, não podem ser atribuídos, subcontratados, delegados ou transferidos por você sem nosso consentimento prévio e por escrito, e qualquer tentativa de cessão, subcontratação, delegação ou transferência em violação do acima exposto será nula e sem efeito. Nós podemos ceder estes Termos livremente. Os termos e condições estabelecidos nestes Termos serão vinculativos para os cessionários.</p>
<p><strong>Sua Privacidade.</strong> Por favor, leia nossa Política de Privacidade.</p>
<p><strong>Informações de Direitos Autorais/Marcas.</strong> Copyright ©. Todos os direitos reservados. Todas as marcas registradas, logotipos e marcas de serviço exibidos no Site são de nossa propriedade ou de propriedade de terceiros. Você não está autorizado a usar essas Marcas sem nosso consentimento prévio por escrito ou o consentimento de tal terceiro que possa ser proprietário das Marcas.</p>
<h2>Informações de Contato</h2>
<p>Email: watch_support@adflash.cn</p>`
        },
        privacy: {
            section1: `<p>Proteger sua privacidade é importante para nós. Esperamos que a seguinte declaração ajude você a entender como os produtos lidam com as informações pessoalmente identificáveis que você pode ocasionalmente nos fornecer via Internet.</p>
<p>Com base em nossos aplicativos e serviços associados, você pode solicitar o uso de um serviço funcional especial fornecido por um terceiro. Se você precisar se inscrever e usar qualquer serviço funcional especial, leia atentamente os termos de serviço para tal serviço funcional especial. Ao aceitar ou usar qualquer serviço funcional especial, você reconhece que leu e aceitou este Acordo e os termos de serviço para tal serviço funcional especial e concorda em se comprometer com eles.</p>
<p>Às vezes, precisamos de informações para fornecer os serviços que você solicita, esta declaração de privacidade aplica-se aos seus produtos.</p>
<h2>Informações que a Equipe Coleta e Recebe.</h2>
<p>Quando você usa o Software e Serviços, podemos coletar e armazenar automaticamente os seguintes tipos de informações:</p>
<p>1. Informações Técnicas. Certas informações técnicas sobre seu dispositivo e seu uso do Software e Serviços e outros softwares e aplicativos de terceiros instalados no seu dispositivo (o "Outro Software"), incluindo o tipo, nome e versão do sistema operacional e Outro Software, incluindo, sem limitação, suas interações com o Software e Serviços, ISP (Provedor de Serviços de Internet), localização (GPS), endereço de protocolo de Internet (IP), identificadores anônimos de usuário, configuração e identificadores do dispositivo (IDFA (Identificador para Anunciantes usado no iOS), ID de Publicidade (Identificador para Anunciantes usado no Android) e similares), versão do Software e Serviços e sua configuração e configurações do Software e Serviços;</p>
<p>2. Informações de Uso. Certas informações relacionadas ao uso sobre seu uso e interação com seu dispositivo, incluindo o Software e Serviços e Outro Software, como quando e como você usa o Software e Serviços e Outro Software. Por exemplo, os aplicativos que você possui e os aplicativos que você usa e com que frequência você os usa. Anúncios que você visualiza, usa e acessa, como você os usa e sua resposta a eles;</p>
<p>3. Redes Sociais. Suas interações nas redes sociais através do Software e Serviços;</p>`,
            section2: `<p>4. Informações Relacionadas ao Registro. Informações que você fornece ao se registrar para o Software e Serviços (se aplicável), como nome, endereços residenciais ou comerciais, endereço de e-mail, números de telefone e fax, data de nascimento e gênero;</p>
<p>5. Informações Relacionadas ao Suporte. Se você se comunicar conosco através da nossa seção de suporte ou via e-mail, também podemos coletar seu endereço de e-mail, nome e qualquer informação fornecida voluntariamente por você em conexão com o suporte. Quando você usa o Software e Serviços, geramos automaticamente um ID de usuário interno anônimo, que é atribuído ao seu uso do Software e Serviços (o "ID de Usuário") e pode ser combinado com qualquer uma das informações acima.</p>
<p>6. Informações do Dispositivo</p>
<p>Coletamos informações sobre seu dispositivo, incluindo o modelo de hardware, sistema operacional e versão, identificadores únicos do dispositivo (incluindo SSAID, GAID), tipo e idioma do navegador, número de telefone do dispositivo móvel e informações da rede móvel, Identidade Internacional de Equipamento Móvel (“IMEI”), Identificador para Publicidade (“IDFA”), Identificador para Vendedor (“IDFV”), Identificador de Circuito Integrado (“ICCID”), Endereço de Controle de Acesso de Mídia (“MAC”), modelo e resolução, que serão usados por nós para calcular o número de dispositivos que usam nossos produtos e nossos Serviços e analisar dados sobre modelos de dispositivos e adaptação gráfica. Você pode optar por não fornecer certas informações, mas talvez não consiga aproveitar muitos de nossos aplicativos. Também coletamos certas informações do dispositivo que nos ajudarão a diagnosticar problemas no (esperançosamente raro) caso de você experimentar qualquer falha ou outro problema ao usar nossos Serviços.</p>
<p>7. Câmera e Fotos</p>
<p>Muitos de nossos Serviços exigem que coletemos imagens e outras informações da câmera e das fotos do seu dispositivo. Acessaremos sua câmera e fotos apenas depois que você nos der seu consentimento. Você pode optar por não fornecê-las, mas talvez não consiga aproveitar muitos de nossos Serviços.</p>
<p>8. Armazenamento Local</p>
<p>Podemos coletar e armazenar informações localmente no seu dispositivo usando mecanismos, como armazenamento web de navegador (incluindo HTML 5) e caches de dados de aplicativos. As informações que armazenamos não serão compartilhadas com terceiros.</p>
<p>9. INFORMAÇÕES NÃO PESSOAIS</p>`,
            section3: `<p>Informações não pessoais são dados em uma forma que não permite associação direta com qualquer indivíduo específico, como seu ID Android, modelo CPN, tamanho da memória, número IMEI do seu telefone, modelo do telefone, ROM, nome do aplicativo instalado e nome do pacote, operador do telefone, localização, instalação, desinstalação, frequência de uso, etc.</p>
<p>Podemos coletar e usar informações não pessoais nas seguintes circunstâncias. Para ter uma melhor compreensão do comportamento do usuário, resolver problemas em produtos e serviços, melhorar nossos produtos, serviços e publicidade, podemos coletar informações não pessoais, como nome do aplicativo instalado e nome do pacote, dados de instalação, frequência de uso, país, equipamento e canal.</p>
<h2>Como a Equipe Usa Suas Informações?</h2>
<p>A Equipe pode usar as informações que coleta para fins analíticos, comerciais e operacionais das seguintes maneiras:</p>
<p>1. para operar e atualizar o Software e Serviços e fornecer a você funcionalidades e recursos existentes e novos;</p>
<p>2. para melhorar e personalizar o Software e Serviços;</p>
<p>3. para fornecer conteúdo personalizado, incluindo a entrega de anúncios baseados em interesses direcionados de nós ou de nossos parceiros;</p>
<p>4. para manter e melhorar a qualidade e a operação do Software e Serviços;</p>
<p>5. para notificá-lo sobre atualizações do Software e Serviços;</p>
<p>6. para fornecer suporte e lidar com suas reclamações e/ou feedback;</p>
<p>7. para tomar qualquer ação em caso de uma disputa envolvendo você em conexão com o Software e Serviços;</p>`,
            section4: `<p>8. para fazer cumprir os termos e condições aplicáveis ao Software e Serviços e para prevenir atividades ilegais e mau uso dos mesmos;</p>
<p>9. para cumprir e satisfazer qualquer lei, regulamentação aplicável, processo legal ou solicitação governamental executável. Compartilhamento de Informações com Terceiros.</p>
<h2>Privacidade das Crianças</h2>
<p>Esses Serviços não se destinam a ninguém com menos de 13 anos. Não coletamos conscientemente informações pessoalmente identificáveis de crianças menores de 13 anos. No caso de descobrirmos que uma criança com menos de 13 anos nos forneceu informações pessoais, imediatamente excluímos isso de nossos servidores. Se você é pai ou responsável e está ciente de que seu filho nos forneceu informações pessoais, por favor, entre em contato comigo para que eu possa tomar as medidas necessárias.</p>
<h2>Alterações a Esta Política de Privacidade</h2>
<p>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Assim, você é aconselhado a revisar esta página periodicamente para quaisquer alterações. Notificaremos você sobre quaisquer alterações ao postar a nova Política de Privacidade nesta página. Essas mudanças são efetivas imediatamente após serem postadas nesta página.</p>
<h2>Entre em Contato Conosco</h2>
<p>Se você tiver alguma dúvida ou sugestão sobre minha Política de Privacidade, não hesite em entrar em contato comigo pelo e-mail: watch_support@adflash.cn</p>`,


        },
        refund: {
            section1: `<p>Obrigado pela sua compra. Se você não estiver completamente satisfeito com sua compra, gostaríamos de ajudá-lo.</p>
<p>Para iniciar o processo de reembolso, siga os passos abaixo.</p>
<p>Produtos de assinatura (por exemplo, Assinatura Básica)</p>
<p>Se você parar de usar, mas esquecer de cancelar sua assinatura, geralmente ficaremos felizes em reembolsar seu pagamento mais recente, desde que você não tenha usado nenhuma sessão ou criado nenhuma imagem após o pagamento.</p>
<p>Para solicitar um reembolso, por favor, envie-nos um pedido de reembolso através da sua conta e através do formulário de feedback. Podemos precisar pedir informações específicas para ajudar a confirmar sua identidade.</p>
<p>Produtos digitais (por exemplo, pacotes de Tempo, assinaturas)</p>
<p>Não serão dados reembolsos se quaisquer pacotes de Tempo e assinaturas tiverem sido usados. Não oferecemos reembolsos parciais ou proporcionais.</p>
<p>Você tem 14 dias corridos a partir da data da transação para solicitar um reembolso.</p>
<p>Assim que recebermos seu pedido de reembolso, nossa equipe verificará o status da sua assinatura para determinar se você é elegível para um reembolso.</p>`,
            section2: `<p>Se for aprovado, seu reembolso será processado. O reembolso será creditado ao [método de pagamento] original usado para a compra.</p>
<p>Reembolsos atrasados ou ausentes</p>
<p>Se você não recebeu seu reembolso, verifique novamente sua conta bancária primeiro.</p>
<p>Depois, entre em contato com a empresa do seu cartão bancário, pode levar algum tempo para que seu reembolso seja oficialmente liberado.</p>
<p>Em seguida, entre em contato com seu banco. Geralmente, leva algum tempo de processamento antes que o reembolso seja liberado.</p>
<p>Se você já fez tudo isso e ainda não recebeu seu reembolso, entre em contato conosco em watch_support@adflash.cn</p>
<p>Itens em Promoção</p>
<p>Apenas itens com preço regular podem ser reembolsados, infelizmente itens em promoção não podem ser reembolsados.</p>`,
        },
        us: {
            section1: `<p>Informações da Empresa</p>
<p>Nome: HONG KONG ADFLASH MEDIA LIMITED</p>
<p>Endereço: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
<p>Email: watch_support@adflash.cn</p>`,
        },
    }
}
